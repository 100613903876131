import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ModalComponent } from './modal/modal.component';
import { MainComponent } from './main/main.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ProjectComponent } from './project/project.component';
import { PricesComponent } from './prices/prices.component';
import { TapsComponent } from './taps/taps.component';
import { CornicesComponent } from './cornices/cornices.component';
import { RollsComponent } from './rolls/rolls.component';
import { PlisseComponent } from './plisse/plisse.component';
import { JalousieComponent } from './jalousie/jalousie.component';
import { BlogComponent } from './blog/blog.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ContactsComponent } from './contacts/contacts.component';
import { Header1Component } from './header1/header1.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    MainComponent,
    PortfolioComponent,
    ProjectComponent,
    PricesComponent,
    TapsComponent,
    CornicesComponent,
    RollsComponent,
    PlisseComponent,
    JalousieComponent,
    BlogComponent,
    ReviewsComponent,
    ContactsComponent,
    Header1Component,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
