import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.css']
})
export class Header1Component implements OnInit {

  public route: string;

  constructor( private router:Router) { }

  ngOnInit() {
    this.subscribeToRoute();
  }

  subscribeToRoute(){
    this.router.events.subscribe(e =>{
      if(e instanceof NavigationEnd){
        this.route = this.router.url;
        console.log(this.route);
      }
    })
  }

}
